'use client';

import { useQuery } from '@tanstack/react-query';
import { EventComplete, ListEventsResponseBody } from 'bff';
import { formatDistance } from 'date-fns';
import { Activity, useEvent } from 'shared-components';
import { WaitForQuery } from './WaitForQuery';
import es from 'date-fns/locale/es';

export const buildListEventsQueryKey = (resourceId: number) =>
  `list-events-${resourceId}`;

export const ActivityTab = ({
  initialData,
  mountEvent,
  fetchEvents,
  id,
  httpClient,
  token,
  portal,
}: {
  initialData: ListEventsResponseBody;
  mountEvent: NonNullable<Parameters<typeof useEvent>[1]>[number];
  fetchEvents: () => Promise<{
    events: EventComplete[];
  }>;
  id: number;
  httpClient: unknown;
  token?: string;
  portal?: boolean;
}) => {
  const query = useQuery([buildListEventsQueryKey(id)], () => fetchEvents(), {
    initialData,
  });

  if (portal) {
    useEvent(httpClient as any, [mountEvent], '/portal', token);
  } else {
    useEvent(httpClient as any, [mountEvent]);
  }

  return (
    <WaitForQuery query={query}>
      {({ events }) => {
        return (
          <Activity>
            {events.map((event, index) => {
              return (
                <Activity.Item
                  index={index}
                  event={event}
                  eventsCount={events.length}
                  key={event.id}
                >
                  <Activity.Marker />

                  <Activity.Content>
                    <Activity.Header>
                      <Activity.TriggeredByAvatar
                        triggeredBy={event.triggered_by}
                      />
                      <Activity.Action triggeredBy={event.triggered_by} />
                      <Activity.TimeAgo className='mt-0 block'>
                        {formatDistance(
                          new Date(),
                          new Date(event.created_at),
                          {
                            locale: es,
                          },
                        )}
                      </Activity.TimeAgo>
                    </Activity.Header>
                    <Activity.Detail />
                    <div className='h-5' />
                  </Activity.Content>
                </Activity.Item>
              );
            })}
          </Activity>
        );
      }}
    </WaitForQuery>
  );
};
