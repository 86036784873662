import {
  Icon,
  IconAlertTriangle,
  IconBuildingBank,
  IconCarCrash,
  IconFall,
  IconReceiptRefund,
  IconUser,
  IconUserExclamation,
} from '@tabler/icons-react';
import {
  ClaimDamageWithRelations,
  ClaimWithRelations,
  FetchClaimDetailResponseBody,
  MaterialDamageWithRelations,
  UpdateClaimRequestBody,
  VehicleWithRelations,
} from 'bff';
import {
  DamageTypes,
  NaturalPerson as INaturalPerson,
  LegalPerson as ILegalPerson,
  VictimTypes,
  Prisma,
  PersonDamageDetail,
  SexTypes,
  IdentificationTypes,
  PersonParentTypes,
  VehicleTypes,
  VehicleUseTypes,
  VehicleCategoryTypes,
  CoverageTypes,
  VictimRoleTypes,
  VictimRelationshipTypes,
  PolicyHolderRelationshipTypes,
  DriverOwnershipTypes,
} from 'database';
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import { DEFAULT_COUNTRY_CODE, countryAndStates } from 'piramid-constants';
import React, { Fragment, useMemo, useState } from 'react';
import {
  NonUndefined,
  Path,
  UseControllerReturn,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import {
  Button,
  Calendar,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  NumberField,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  Textarea,
  cn,
} from 'ui';
import {
  currencyFormat,
  getCountryLabel,
  getStateLabel,
  nativeEnumToArray,
} from 'utils';
import {
  ClaimUpdateSegment,
  useSafeClaimUpdate,
} from '../contexts/ClaimUpdateContext';
import { nativeEnum } from 'zod';
import { EditClaimButton } from './EditClaimButton';
import { CalendarIcon } from 'lucide-react';

type NaturalPersonPopulated = Prisma.NaturalPersonGetPayload<{
  include: {
    parents: true;
  };
}>;

export const damageIcons: {
  [K in DamageTypes]: Icon;
} = {
  recovery: IconReceiptRefund,
  material: IconCarCrash,
  person: IconFall,
};

const personTypesIcons: {
  [K in VictimTypes]: Icon;
} = {
  natural_person: IconUser,
  legal_person: IconBuildingBank,
};

const CardLabel = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'font-bold flex flex-row items-center space-x-2',
        className,
      )}
    >
      {children}
    </div>
  );
};

const CardValue = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={cn('min-h-12', className)}>{children || '-'}</div>;
};

const CardAttribute = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('flex text-sm flex-col space-y-1', className)}>
      {children}
    </div>
  );
};

const CardGroup = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('rounded-md border bg-card shadow p-3', className)}>
      {children}
    </div>
  );
};

const CardGroupLabel = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <CardLabel className={cn('text-left', className)}>{children}</CardLabel>
  );
};

const Claimant = ({
  data,
  segment,
}: {
  data: Pick<
    FetchClaimDetailResponseBody['claimants'][number],
    'type' | 'legal_person' | 'natural_person'
  >;
  segment: ClaimUpdateSegment;
}) => {
  switch (data.type) {
    case 'legal_person':
      return <LegalPerson data={data.legal_person!} />;

    case 'natural_person':
      return <NaturalPerson segment={segment} data={data.natural_person!} />;
  }
};

const LegalPerson = ({
  data,
  className,
}: {
  data: ILegalPerson;
  className?: string;
}) => {
  const t = useTranslations();

  return (
    <CardGroup
      className={cn(
        'col-span-1 bg-card border-none shadow-none p-0',
        className,
      )}
    >
      <CardGroupFields>
        <CardAttribute>
          <CardLabel>Tipo</CardLabel>
          <CardValue>Persona jurídica</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Entidad</CardLabel>
          <CardValue>{t(`legal_person.type.${data.type}`)}</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Razón social</CardLabel>
          <CardValue>{data.social_reason}</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>CUIT</CardLabel>
          <CardValue>{data.cuit}</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Provincia</CardLabel>
          <CardValue>
            {getStateLabel(DEFAULT_COUNTRY_CODE, data.state)}
          </CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Ciudad</CardLabel>
          <CardValue>{data.city}</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Dirección</CardLabel>
          <CardValue>{data.address}</CardValue>
        </CardAttribute>

        <CardAttribute>
          <CardLabel>Código Postal</CardLabel>
          <CardValue>{data.zip_code}</CardValue>
        </CardAttribute>
      </CardGroupFields>
    </CardGroup>
  );
};

type FieldViewSpec =
  | {
      type: 'literal';
      literal: string;
    }
  | {
      type: 'accessor';
      accessor: (row: UpdateClaimRequestBody) => string;
    };

type FieldEditSpec =
  | {
      type: 'number';
      formFieldName: Path<UpdateClaimRequestBody>;
    }
  | {
      type: 'select';
      options: { label: string; value: string }[];
      formFieldName: Path<UpdateClaimRequestBody>;
    }
  | {
      type: 'text';
      formFieldName: Path<UpdateClaimRequestBody>;
    }
  | {
      type: 'textarea';
      formFieldName: Path<UpdateClaimRequestBody>;
    }
  | {
      type: 'date';
      formFieldName: Path<UpdateClaimRequestBody>;
    }
  | {
      type: 'check';
      formFieldName: Path<UpdateClaimRequestBody>;
    };

type FieldSpec = {
  label: string;
  view: FieldViewSpec;
  edit?: FieldEditSpec;
};

const FieldViewRender = ({ field }: { field: FieldSpec }) => {
  const update = useSafeClaimUpdate();

  switch (field.view.type) {
    case 'literal':
      return field.view.literal;

    case 'accessor':
      return field.view.accessor(update.form?.getValues());
  }
};

const DateField = ({
  spec,
  control,
}: {
  spec: Exclude<FieldSpec, { edit: undefined | null }>;
  control: UseControllerReturn;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const field = control.field;

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            size='sm'
            data-test-id='date-picker'
            variant={'outline'}
            className={cn(
              'w-full pl-3 text-left font-normal',
              !control.field.value && 'text-muted-foreground',
            )}
            onClick={() => setIsOpen(!isOpen)}
          >
            {control.field.value ? (
              format(new Date(control.field.value), 'PPP', {
                locale: es,
              })
            ) : (
              <span>Selecciona una fecha</span>
            )}
            <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <Calendar
          mode='single'
          selected={field.value}
          onSelect={(day) => {
            field.onChange(day);
            setIsOpen(!isOpen);
          }}
          disabled={(date) =>
            date > new Date() || date < new Date('1900-01-01')
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

const FieldEditControl = ({
  spec,
  control,
}: {
  spec: Exclude<FieldSpec, { edit: undefined | null }>;
  control: UseControllerReturn;
}) => {
  switch (spec.edit!.type) {
    case 'select': {
      return (
        <Select
          onValueChange={control.field.onChange}
          defaultValue={control.field.value}
        >
          <SelectTrigger size='sm'>
            <SelectValue placeholder='Selecciona una opción' />
          </SelectTrigger>
          <SelectContent>
            {spec.edit!.options.map((option, index) => {
              return (
                <SelectItem key={index} value={option.value}>
                  {option.label}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      );
    }

    case 'number':
      return <NumberField {...control.field} size='sm' />;

    case 'text':
      return <Input {...control.field} size='sm' />;

    case 'textarea':
      return <Textarea {...control.field} size='sm' />;

    case 'check':
      return (
        <Switch
          size='sm'
          checked={control.field.value}
          onCheckedChange={control.field.onChange}
          ref={control.field.ref}
        />
      );

    case 'date':
      return <DateField control={control} spec={spec} />;
  }
};

const FieldEditRender = ({ field }: { field: FieldSpec }) => {
  const update = useSafeClaimUpdate();

  if (!field.edit) return <FieldViewRender field={field} />;

  return (
    <FormField
      control={update.form!.control}
      name={field.edit.formFieldName}
      render={(fieldControl) => {
        return (
          <FormItem>
            <FormControl>
              <FieldEditControl spec={field} control={fieldControl} />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

interface FieldsSegmentRenderProps {
  fields: FieldSpec[];
  segment: ClaimUpdateSegment;
}

const GroupedFieldsSegmentRender = ({
  className,
  children,
  ...props
}: FieldsSegmentRenderProps & {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <CardGroup
      className={cn(
        'col-span-1 bg-card border-none shadow-none p-0',
        className,
      )}
    >
      <CardGroupFields>
        <FieldsSegmentRender {...props} />
        {children}
      </CardGroupFields>
    </CardGroup>
  );
};

const FieldsSegmentRender = ({ fields, segment }: FieldsSegmentRenderProps) => {
  const update = useSafeClaimUpdate();

  const isEditingCurrentSegment =
    !!segment && update.editingSegment === segment;

  return fields.map((field) => {
    return (
      <CardAttribute>
        <CardLabel>{field.label}</CardLabel>
        {isEditingCurrentSegment ? (
          <FieldEditRender field={field} />
        ) : (
          <FieldViewRender field={field} />
        )}
      </CardAttribute>
    );
  });
};

const NaturalPerson = ({
  data,
  className,
  children,
  segment,
  prefix: _prefix = 'victim',
}: {
  data: NaturalPersonPopulated;
  className?: string;
  children: React.ReactNode;
  segment: ClaimUpdateSegment;
  prefix?: `victim` | `claimants.${number}`;
}) => {
  const t = useTranslations();

  const update = useSafeClaimUpdate();

  const prefix = `${_prefix}.natural_person` as const;

  console.log('nombre', `${prefix}.first_name`);

  const fields: FieldSpec[] = [
    {
      label: 'Tipo',
      view: {
        type: 'literal',
        literal: 'Persona física',
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.first_name.label`),
      view: {
        type: 'accessor',
        accessor: () => data.first_name,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.first_name`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.last_name.label`),
      view: {
        type: 'accessor',
        accessor: () => data.last_name,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.last_name`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.birth_date.label`),
      view: {
        type: 'accessor',
        accessor: () => format(new Date(data.birth_date), 'dd/MM/yyyy'),
      },
      edit: {
        type: 'date',
        formFieldName: `${prefix}.birth_date`,
      },
    },

    {
      label: t(`claim.attributes.victim.natural_person.sex.label`),
      view: {
        type: 'accessor',
        accessor: () => t(`sex.${data.sex}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(SexTypes).map((sex) => {
          return {
            label: t(`sex.${sex}`),
            value: sex,
          };
        }),
        formFieldName: `${prefix}.sex`,
      },
    },
    {
      label: t(
        `claim.attributes.victim.natural_person.identification_type.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () => t(`identification-type.${data.identification_type}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(IdentificationTypes).map((type) => {
          return {
            label: t(`identification-type.${type}`),
            value: type,
          };
        }),
        formFieldName: `${prefix}.identification_type`,
      },
    },
    {
      label: t(
        `claim.attributes.victim.natural_person.identification_value.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () => data.identification_value,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.identification_value`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.nationality.label`),
      view: {
        type: 'accessor',
        accessor: () => getCountryLabel(data.nationality),
      },
      edit: {
        type: 'select',
        formFieldName: `${prefix}.nationality`,
        options: countryAndStates.map((country) => {
          return {
            label: country.name,
            value: country.code,
          };
        }),
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.state.label`),
      view: {
        type: 'accessor',
        accessor: () => getStateLabel(DEFAULT_COUNTRY_CODE, data.state),
      },
      edit: {
        type: 'select',
        formFieldName: `${prefix}.state`,
        get options() {
          if (!update?.form) return [];

          const countrySpec = countryAndStates.find(
            (country) =>
              country.code === update.form!.getValues(`${prefix}.nationality`),
          );

          if (!countrySpec) return [];

          return countrySpec.states.map((state) => {
            return {
              label: state.name,
              value: state.code,
            };
          });
        },
      },
    },

    {
      label: t(`claim.attributes.victim.natural_person.city.label`),
      view: {
        type: 'accessor',
        accessor: () => data.city,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.city`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.address.label`),
      view: {
        type: 'accessor',
        accessor: () => data.address,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.address`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.zip_code.label`),
      view: {
        type: 'accessor',
        accessor: () => data.zip_code,
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.zip_code`,
      },
    },
    {
      label: t(`claim.attributes.victim.natural_person.cellphone.label`),
      view: {
        type: 'accessor',
        accessor: () => data.cellphone || '',
      },
      edit: {
        type: 'text',
        formFieldName: `${prefix}.cellphone`,
      },
    },
  ];

  console.log('data', data);

  const motherFields = data.parents
    .map((parent, index) => ({
      ...parent,
      index,
    }))
    .filter((parent) => parent.type === 'mother')
    .flatMap((parent): FieldSpec[] => {
      return [
        {
          label: 'Familiar',
          view: {
            type: 'accessor',
            accessor: () => t(`victim_relationship.${parent.type}`),
          },
          edit: {
            type: 'select',
            formFieldName: `${prefix}.parents.${parent.index}.type`,
            options: nativeEnumToArray(PersonParentTypes).map((type) => ({
              label: t(`victim_relationship.${type}`),
              value: type,
            })),
          },
        },
        {
          label: 'Nombre',
          view: {
            type: 'accessor',
            accessor: () => parent.first_name,
          },
          edit: {
            type: 'text',
            formFieldName: `${prefix}.parents.${parent.index}.first_name`,
          },
        },
        {
          label: 'Apellido',
          view: {
            type: 'accessor',
            accessor: () => parent.last_name,
          },
          edit: {
            type: 'text',
            formFieldName: `${prefix}.parents.${parent.index}.last_name`,
          },
        },

        {
          label: 'Documento',
          view: {
            type: 'accessor',
            accessor: () => parent.identification,
          },
          edit: {
            type: 'text',
            formFieldName: `${prefix}.parents.${parent.index}.identification`,
          },
        },
      ];
    });

  const fatherFields = data.parents
    .filter((parent) => parent.type === 'father')
    .flatMap((parent, index): FieldSpec[] => {
      return [
        {
          label: 'Familiar',
          view: {
            type: 'accessor',
            accessor: () => t(`victim_relationship.${parent.type}`),
          },
          edit: {
            type: 'select',
            formFieldName: `victim.natural_person.parents.${index}.type`,
            options: nativeEnumToArray(PersonParentTypes).map((type) => ({
              label: t(`victim_relationship.${type}`),
              value: type,
            })),
          },
        },
        {
          label: 'Nombre',
          view: {
            type: 'accessor',
            accessor: () => parent.first_name,
          },
          edit: {
            type: 'text',
            formFieldName: `victim.natural_person.parents.${index}.first_name`,
          },
        },
        {
          label: 'Apellido',
          view: {
            type: 'accessor',
            accessor: () => parent.last_name,
          },
          edit: {
            type: 'text',
            formFieldName: `victim.natural_person.parents.${index}.last_name`,
          },
        },

        {
          label: 'Documento',
          view: {
            type: 'accessor',
            accessor: () => parent.identification,
          },
          edit: {
            type: 'text',
            formFieldName: `victim.natural_person.parents.${index}.identification`,
          },
        },
      ];
    });

  return (
    <>
      <GroupedFieldsSegmentRender segment={segment} fields={fields}>
        {children}
      </GroupedFieldsSegmentRender>
      {(motherFields.length > 0 || fatherFields.length > 0) && (
        <>
          <Separator />
          <ClaimSummary.CardGroupLabel>Padres</ClaimSummary.CardGroupLabel>
          <div>
            {motherFields.length > 0 && (
              <GroupedFieldsSegmentRender
                segment={segment}
                fields={motherFields}
              />
            )}

            {fatherFields.length > 0 && (
              <GroupedFieldsSegmentRender
                segment={segment}
                fields={fatherFields}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const CardGroupSeparator = () => {
  return <Separator className='my-2' />;
};

const CardGroupFields = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('py-2 grid grid-cols-2 gap-4', className)}>
      {children}
    </div>
  );
};

const VehicleDamage = ({
  data,
  index,
}: {
  data: VehicleWithRelations;
  index: number;
}) => {
  const t = useTranslations();

  const fields: FieldSpec[] = [
    {
      label: t(`claim.attributes.damages.material.vehicle.type.label`),
      view: {
        type: 'accessor',
        accessor: () => t(`vehicle.${data.type}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(VehicleTypes).map((type) => ({
          label: t(`vehicle.${type}`),
          value: type,
        })),
        formFieldName: `damages.${index}.material.vehicle.type`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.make.label`),
      view: {
        type: 'accessor',
        accessor: () => data.make,
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.make`,
      },
    },

    {
      label: t(`claim.attributes.damages.material.vehicle.version.label`),
      view: {
        type: 'accessor',
        accessor: () => data.version || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.version`,
      },
    },

    {
      label: t(`claim.attributes.damages.material.vehicle.model.label`),
      view: {
        type: 'accessor',
        accessor: () => data.model,
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.model`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.year.label`),
      view: {
        type: 'accessor',
        accessor: () => data.year!,
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.year`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.use.label`),
      view: {
        type: 'accessor',
        accessor: () => t(`vehicle.use.${data.use}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(VehicleUseTypes).map((type) => ({
          label: t(`vehicle.use.${type}`),
          value: type,
        })),
        formFieldName: `damages.${index}.material.vehicle.use`,
      },
    },

    {
      label: t(`claim.attributes.damages.material.vehicle.market_value.label`),
      view: {
        type: 'accessor',
        accessor: () =>
          data.market_value
            ? currencyFormat(data.market_value, DEFAULT_COUNTRY_CODE)
            : '',
      },
      edit: {
        type: 'number',
        formFieldName: `damages.${index}.material.vehicle.market_value`,
      },
    },

    {
      label: t(`claim.attributes.damages.material.vehicle.category.label`),
      view: {
        type: 'accessor',
        accessor: () => t(`vehicle.category.${data.category}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(VehicleCategoryTypes).map((type) => ({
          label: t(`vehicle.category.${type}`),
          value: type,
        })),
        formFieldName: `damages.${index}.material.vehicle.category`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.license_plate.label`),
      view: {
        type: 'accessor',
        accessor: () => data.license_plate || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.license_plate`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.chassis.label`),
      view: {
        type: 'accessor',
        accessor: () => data.chassis || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.chassis`,
      },
    },
    {
      label: t(`claim.attributes.damages.material.vehicle.engine.label`),
      view: {
        type: 'accessor',
        accessor: () => data.engine || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.engine`,
      },
    },

    {
      label: t(
        `claim.attributes.damages.material.vehicle.driver.first_name.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () => data.driver?.first_name || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.driver.first_name`,
      },
    },
    {
      label: t(
        `claim.attributes.damages.material.vehicle.driver.last_name.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () => data.driver?.first_name || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.driver.last_name`,
      },
    },
    {
      label: t(
        `claim.attributes.damages.material.vehicle.driver.cellphone.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () => data.driver?.cellphone || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.vehicle.driver.cellphone`,
      },
    },
    {
      label: t(
        `claim.attributes.damages.material.vehicle.driver.ownership.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () =>
          data.driver?.ownership
            ? t(`driver-ownership.${data.driver?.ownership}`)
            : '',
      },
      edit: {
        type: 'select',
        formFieldName: `damages.${index}.material.vehicle.driver.ownership`,
        options: nativeEnumToArray(DriverOwnershipTypes).map((type) => ({
          label: t(`driver-ownership.${type}`),
          value: type,
        })),
      },
    },
  ];

  return <FieldsSegmentRender fields={fields} segment={`damages`} />;
};

const RealPropertyDamage = ({
  data,
}: {
  data: MaterialDamageWithRelations;
}) => {
  const country = countryAndStates.find(
    (c) => c.code === data.real_property!.country,
  );

  const state = countryAndStates
    .find((c) => c.code === data.real_property!.country)
    ?.states?.find((s) => s.code === data.real_property!.state);

  const t = useTranslations();

  return (
    <>
      {country && (
        <CardAttribute>
          <CardLabel>País</CardLabel>
          <CardValue>{country.name}</CardValue>
        </CardAttribute>
      )}

      {state && (
        <CardAttribute>
          <CardLabel>Provincia</CardLabel>
          <CardValue>{state.name}</CardValue>
        </CardAttribute>
      )}

      <CardAttribute>
        <CardLabel>Ciudad</CardLabel>
        <CardValue>{data.real_property!.city}</CardValue>
      </CardAttribute>

      <CardAttribute>
        <CardLabel>Dirección</CardLabel>
        <CardValue>{data.real_property!.address}</CardValue>
      </CardAttribute>

      <CardAttribute>
        <CardLabel>Código Postal</CardLabel>
        <CardValue>{data.real_property!.zip_code}</CardValue>
      </CardAttribute>

      <CardAttribute>
        <CardLabel>Tipo</CardLabel>
        <CardValue>
          {t(`real_property.type.${data.real_property!.type}`)}
        </CardValue>
      </CardAttribute>

      <CardAttribute>
        <CardLabel>Propiedad</CardLabel>
        <CardValue>
          {t(`real_property.ownership.${data.real_property!.ownership}`)}
        </CardValue>
      </CardAttribute>
    </>
  );
};

const PersonDamageDetailsDataTable = ({
  details,
}: {
  details: PersonDamageDetail[];
}) => {
  const t = useTranslations();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Daño</TableHead>
          <TableHead>Severidad</TableHead>
          <TableHead className='text-right'>Partes</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {details.map((detail) => (
          <TableRow key={detail.id}>
            <TableCell className='font-medium'>
              {t(`injuries.${detail.damage}`)}
            </TableCell>
            <TableCell className='font-medium'>
              {t(`severities.${detail.severity}`)}
            </TableCell>
            <TableCell className='text-right'>
              {detail.body_parts && detail.body_parts?.length > 0
                ? detail.body_parts
                    .map((part) => t(`fractures.${part}`))
                    .join(', ')
                : ''}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const ROOT_TRANSLATION_NAMESPACE = '';

const PersonDamage = ({
  data,
  index,
}: {
  data: ClaimDamageWithRelations;
  index: number;
}) => {
  const t = useTranslations(ROOT_TRANSLATION_NAMESPACE, '');

  const fields: FieldSpec[] = [
    {
      label: t(`claim.attributes.damages.person.victim_role.label`),
      view: {
        type: 'accessor',
        accessor: () => t(`victim-injuries-roles.${data.person!.victim_role}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(VictimRoleTypes).map((type) => ({
          label: t(`victim-injuries-roles.${type}`),
          value: type,
        })),
        formFieldName: `damages.${index}.person.victim_role`,
      },
    },
    {
      label: t(
        'claim.attributes.damages.person.policyholder_relationship.label',
      ),
      view: {
        type: 'accessor',
        accessor: () =>
          t(
            `local-insured-relationships.${
              data.person!.policyholder_relationship
            }`,
          ),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(PolicyHolderRelationshipTypes).map(
          (type) => ({
            label: t(`local-insured-relationships.${type}`),
            value: type,
          }),
        ),
        formFieldName: `damages.${index}.person.policyholder_relationship`,
      },
    },

    {
      label: t(
        'claim.attributes.damages.person.policyholder_relationship_other.label',
      ),
      view: {
        type: 'accessor',
        accessor: () => data.person!.policyholder_relationship_other || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.person.policyholder_relationship_other`,
      },
    },

    {
      label: t('claim.attributes.damages.person.health_insurance_fund.label'),
      view: {
        type: 'accessor',
        accessor: () => data.person!.health_insurance_fund || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.person.health_insurance_fund`,
      },
    },
    {
      label: t(
        `claim.attributes.damages.person.treatment_worker_compensation_insurer.label`,
      ),
      view: {
        type: 'accessor',
        accessor: () =>
          data.person!.treatment_worker_compensation_insurer || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.person.treatment_worker_compensation_insurer`,
      },
    },
    {
      label: t(
        'claim.attributes.damages.person.treatment_medical_institution.label',
      ),
      view: {
        type: 'accessor',
        accessor: () => data.person!.treatment_medical_institution || '',
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.person.treatment_medical_institution`,
      },
    },
    {
      label: t('claim.attributes.damages.person.surgery_description.label'),
      view: {
        type: 'accessor',
        accessor: () => data.person!.surgery_description || '',
      },
      edit: {
        type: 'textarea',
        formFieldName: `damages.${index}.person.surgery_description`,
      },
    },
  ];

  return (
    <>
      <CardAttribute>
        <CardLabel>Subtipo</CardLabel>
        <CardValue>{t(`damages.${data.person!.type}`)}</CardValue>
      </CardAttribute>

      <FieldsSegmentRender fields={fields} segment='damages' />

      {data.person!.details?.length > 0 && (
        <div className='col-span-full'>
          <Separator className='my-4' />
          <ClaimSummary.Header>
            <ClaimSummary.CardGroupLabel>
              <ClaimSummary.Icon>
                <IconFall />
              </ClaimSummary.Icon>
              Lesiones
            </ClaimSummary.CardGroupLabel>
          </ClaimSummary.Header>
          <PersonDamageDetailsDataTable details={data.person!.details} />
        </div>
      )}
    </>
  );
};

const MaterialDamageTypeAttributes = ({
  data,
  index,
}: {
  data: ClaimDamageWithRelations;
  index: number;
}) => {
  const t = useTranslations();

  switch (data.material!.type) {
    case 'vehicle':
      return <VehicleDamage index={index} data={data.material!.vehicle!} />;

    case 'real_property':
      return <RealPropertyDamage data={data.material!} />;

    default:
      return null;
  }
};

const MaterialDamageAttributes = ({
  data,
  index,
}: {
  data: ClaimDamageWithRelations;
  index: number;
}) => {
  const t = useTranslations();

  const fields: FieldSpec[] = [
    {
      label: 'Aseguradora',
      view: {
        type: 'accessor',
        accessor: () => data.material!.insurance!.insurer,
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.insurance.insurer`,
      },
    },

    {
      label: 'Cobertura',
      view: {
        type: 'accessor',
        accessor: () => t(`coverages.${data.material!.insurance!.coverage}`),
      },
      edit: {
        type: 'select',
        formFieldName: `damages.${index}.material.insurance.coverage`,
        options: nativeEnumToArray(CoverageTypes).map((type) => ({
          label: t(`coverages.${type}`),
          value: type,
        })),
      },
    },
    {
      label: 'Número de Póliza',
      view: {
        type: 'accessor',
        accessor: () => data.material!.insurance!.policy_number,
      },
      edit: {
        type: 'text',
        formFieldName: `damages.${index}.material.insurance.policy_number`,
      },
    },
    {
      label: 'Monto franquicia',
      view: {
        type: 'accessor',
        accessor: () =>
          data.material.insurance?.franchise_amount
            ? currencyFormat(data.material.insurance.franchise_amount)
            : '',
      },
      edit: {
        type: 'number',
        formFieldName: `damages.${index}.material.insurance.franchise_amount`,
      },
    },
  ];

  return <FieldsSegmentRender fields={fields} segment='damages' />;
};

const MaterialDamage = ({
  data,
  index,
}: {
  data: ClaimDamageWithRelations;
  index: number;
}) => {
  const t = useTranslations();

  return (
    <>
      <CardAttribute>
        <CardLabel>Subtipo</CardLabel>
        <CardValue>{t(`materialDamage.${data.material!.type}`)}</CardValue>
      </CardAttribute>

      <MaterialDamageTypeAttributes index={index} data={data} />

      {data.material?.insurance && (
        <>
          <MaterialDamageAttributes data={data} index={index} />
        </>
      )}
    </>
  );
};

const RecoveryDamage = ({ data }: { data: ClaimDamageWithRelations }) => {
  const t = useTranslations();

  const state = countryAndStates
    .find((c) => c.code === DEFAULT_COUNTRY_CODE)
    ?.states?.find((s) => s.code === data.recovery!.institution!.state);

  return (
    <>
      <CardAttribute>
        <CardLabel>Institución</CardLabel>
        <CardValue>{data.recovery!.institution.social_reason}</CardValue>
      </CardAttribute>
      <CardAttribute>
        <CardLabel>Tipo</CardLabel>
        <CardValue>
          {t(`legalPersonType.${data.recovery!.institution.type}`)}
        </CardValue>
      </CardAttribute>
      {state && (
        <CardAttribute>
          <CardLabel>Provincia</CardLabel>
          <CardValue>{state.name}</CardValue>
        </CardAttribute>
      )}

      <CardAttribute>
        <CardLabel>Ciudad</CardLabel>
        <CardValue>{data.recovery!.institution.city}</CardValue>
      </CardAttribute>
      <CardAttribute>
        <CardLabel>Dirección</CardLabel>
        <CardValue>{data.recovery!.institution.address}</CardValue>
      </CardAttribute>
      <CardAttribute>
        <CardLabel>Código Postal</CardLabel>
        <CardValue>{data.recovery!.institution.zip_code}</CardValue>
      </CardAttribute>
      <CardAttribute>
        <CardLabel>CUIT</CardLabel>
        <CardValue>{data.recovery!.institution.cuit}</CardValue>
      </CardAttribute>
    </>
  );
};

const Icon = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className='[&>svg]:w-4 [&>svg]:h-4 [&>svg]:mr-1'>{children}</span>
  );
};

const LocationAttributes = ({
  data,
}: {
  data: Pick<
    ClaimWithRelations,
    | 'desired_support_location_state'
    | 'desired_support_location_state_municipality'
  >;
}) => {
  const state = countryAndStates
    .find((c) => c.code === DEFAULT_COUNTRY_CODE)
    ?.states.find((s) => s.code === data.desired_support_location_state);

  return (
    <CardAttribute>
      <CardLabel>Ubicación</CardLabel>
      <CardValue>
        {state?.name}{' '}
        {data.desired_support_location_state_municipality
          ? ` - ${data.desired_support_location_state_municipality}`
          : ''}
      </CardValue>
    </CardAttribute>
  );
};

const DamageAttributes = ({
  damage,
  index,
}: {
  damage: ClaimDamageWithRelations;
  index: number;
}) => {
  switch (damage.type) {
    case 'material':
      return <MaterialDamage index={index} data={damage} />;

    case 'recovery':
      return <RecoveryDamage data={damage} />;

    case 'person':
      return <PersonDamage index={index} data={damage} />;
  }
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='flex flex-row justify-between items-center h-10'>
      {children}
    </div>
  );
};

export const ClaimSummary = ({ children }: { children: React.ReactNode }) => {
  return <div>{children}</div>;
};

const ClaimantAdditionalAttributes = ({
  claimant,
  index,
}: {
  claimant: Claimant;
  index: number;
}) => {
  const t = useTranslations();

  const fields: FieldSpec[] = [
    {
      label: t(`claim.attributes.claimants.victim_relationship.label`),
      view: {
        type: 'accessor',
        accessor: () =>
          t(`victim_relationship.${claimant.victim_relationship}`),
      },
      edit: {
        type: 'select',
        options: nativeEnumToArray(VictimRelationshipTypes).map((type) => ({
          label: t(`victim_relationship.${type}`),
          value: type,
        })),
        formFieldName: `claimants.${index}.victim_relationship`,
      },
    },
    {
      label: t(`claim.attributes.claimants.victim_relationship_other.label`),
      view: {
        type: 'accessor',
        accessor: () => claimant.victim_relationship_other || '',
      },
      edit: {
        type: 'text',
        formFieldName: `claimants.${index}.victim_relationship_other`,
      },
    },
  ];

  return <FieldsSegmentRender fields={fields} segment='claimants' />;
};

const ClaimantCard = ({
  claimant,
  index,
}: {
  claimant: Claimant;
  index: number;
}) => {
  const t = useTranslations();

  if (claimant.type !== 'natural_person') return null;

  return (
    <ClaimSummary.NaturalPerson
      segment={'claimants'}
      data={claimant.natural_person!}
      prefix={`claimants.${index}`}
    >
      <ClaimantAdditionalAttributes claimant={claimant} index={index} />
    </ClaimSummary.NaturalPerson>
  );
};

type Claimant = FetchClaimDetailResponseBody['claimants'][number];
type Damage = FetchClaimDetailResponseBody['damages'][number];

const DamageEditableFields = ({
  damage,
  index,
}: {
  damage: Damage;
  index: number;
}) => {
  const fields: FieldSpec[] = [
    {
      label: '¿Fue arreglado?',
      view: {
        type: 'accessor',
        accessor: () => (damage.fixed ? 'Si' : 'No'),
      },
      edit: {
        type: 'check',
        formFieldName: `damages.${index}.fixed`,
      },
    },
  ];

  return <FieldsSegmentRender fields={fields} segment='damages' />;
};

const Damages = ({ damages }: { damages: Damage[] }) => {
  const [damageIndex, setDamageIndex] = useState(0);

  const damage = damages[damageIndex];

  const t = useTranslations();

  const update = useSafeClaimUpdate();

  return (
    <ClaimSummary.CardGroup className='col-span-1'>
      <ClaimSummary.Header>
        <ClaimSummary.CardGroupLabel>
          <ClaimSummary.Icon>
            <IconAlertTriangle />
          </ClaimSummary.Icon>
          Daños
        </ClaimSummary.CardGroupLabel>
        <div className='flex flex-row items-center space-x-2'>
          <Select
            onValueChange={(value) => setDamageIndex(Number(value))}
            defaultValue={damageIndex.toString()}
          >
            <SelectTrigger size='sm' className='w-48'>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {damages.map((c, index) => {
                return (
                  <SelectItem key={index} value={index.toString()}>
                    {t(`damages.${c.type}`)}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          {update.enabled && <EditClaimButton segment='damages' />}
        </div>
      </ClaimSummary.Header>
      <ClaimSummary.CardGroupSeparator />
      <ClaimSummary.CardGroup className='border-none shadow-none px-0'>
        <ClaimSummary.CardGroupFields>
          {damage?.description && (
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Descripción</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {damage?.description}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
          )}

          <DamageEditableFields damage={damage} index={damageIndex} />

          <ClaimSummary.DamageAttributes index={damageIndex} damage={damage} />
        </ClaimSummary.CardGroupFields>
      </ClaimSummary.CardGroup>
    </ClaimSummary.CardGroup>
  );
};

const Claimants = ({ claimants }: { claimants: Claimant[] }) => {
  const [claimantIndex, setClaimantIndex] = useState(0);

  const claimant = claimants[claimantIndex];

  const buildLabel = (claimant: Claimant): string =>
    claimant.type === 'natural_person'
      ? `${claimant.natural_person?.first_name} ${claimant.natural_person?.last_name}`
      : claimant.legal_person?.social_reason!;

  const update = useSafeClaimUpdate();

  return (
    <ClaimSummary.CardGroup className='col-span-1'>
      <ClaimSummary.Header>
        <ClaimSummary.CardGroupLabel>
          <ClaimSummary.Icon>
            <IconUserExclamation />
          </ClaimSummary.Icon>
          Reclamantes
        </ClaimSummary.CardGroupLabel>
        <div className='flex flex-row items-center space-x-2'>
          <Select
            onValueChange={(value) => setClaimantIndex(Number(value))}
            defaultValue={claimantIndex.toString()}
          >
            <SelectTrigger size='sm' className='w-48 truncate'>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {claimants.map((c, index) => {
                return (
                  <SelectItem value={index.toString()}>
                    {buildLabel(c)}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          {update.enabled && <EditClaimButton segment='claimants' />}
        </div>
      </ClaimSummary.Header>

      <ClaimSummary.CardGroupSeparator />
      <ClaimSummary.CardGroupFields className={cn('grid grid-cols-1')}>
        <ClaimantCard index={claimantIndex} claimant={claimant} />
      </ClaimSummary.CardGroupFields>
    </ClaimSummary.CardGroup>
  );
};

export const CardList = ({ children }: { children: React.ReactNode }) => {
  return <div className='grid grid-cols-2 gap-4'>{children}</div>;
};

ClaimSummary.CardList = CardList;
ClaimSummary.Damages = Damages;
ClaimSummary.Claimants = Claimants;
ClaimSummary.DamageAttributes = DamageAttributes;
ClaimSummary.LocationAttributes = LocationAttributes;
ClaimSummary.PersonDamage = PersonDamage;
ClaimSummary.Claimant = Claimant;
ClaimSummary.Victim = Claimant;
ClaimSummary.Icon = Icon;
ClaimSummary.MaterialDamage = MaterialDamage;
ClaimSummary.RecoveryDamage = RecoveryDamage;
ClaimSummary.NaturalPerson = NaturalPerson;
ClaimSummary.CardAttribute = CardAttribute;
ClaimSummary.CardLabel = CardLabel;
ClaimSummary.CardValue = CardValue;
ClaimSummary.CardGroupFields = CardGroupFields;
ClaimSummary.CardGroup = CardGroup;
ClaimSummary.CardGroupLabel = CardGroupLabel;
ClaimSummary.CardGroupSeparator = CardGroupSeparator;
ClaimSummary.CardGroupFields = CardGroupFields;
ClaimSummary.Header = Header;
