'use client';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredFieldMessage } from 'piramid-constants';
import { useApiError } from '../hooks/useApiError';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInRequestBody } from 'bff';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export interface LoginUserFormProps {
  loginUser: (data: FormData) => Promise<any>;
  signInIDP: SignInIDPFn;
  forgotPasswordHref?: string;
  backToIdpStrategy?: () => void;
}

export const LoginUserForm = ({
  loginUser,
  signInIDP,
  forgotPasswordHref,
  backToIdpStrategy,
}: LoginUserFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailTyped, setEmailTyped] = useState(false);

  const { handleError } = useApiError();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    loginUser(data)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  return (
    <div className='animate-fadein'>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className='grid grid-cols-1 mb-5 '
        >
          {!emailTyped && (
            <div className='w-full grid grid-cols-1 gap-4'>
              <FormField
                control={form.control}
                name='email'
                defaultValue=''
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          className='h-11.1 rounded-[.625rem] border-solid border-piramid-black placeholder:text-stone-300 placeholder:text-xs focus:border-piramid-orange focus-visible:ring-transparent'
                          placeholder='Ingresa tu email'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              form.trigger('email').then((isValid) => {
                                if (isValid) {
                                  setEmailTyped(true);
                                }
                              });
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <Button
                loading={isLoading}
                type='button'
                className='bg-piramid-black hover:bg-piramid-black h-11.1 rounded-[.625rem] text-[1rem]'
                onClick={async () => {
                  if (await form.trigger('email')) {
                    setEmailTyped(true);
                  }
                }}
              >
                Continuar
              </Button>
            </div>
          )}

          {emailTyped && (
            <div className='w-full grid grid-cols-1 animate-fadein'>
              <FormField
                control={form.control}
                name='password'
                defaultValue=''
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Input
                          type='password'
                          {...field}
                          className='h-11.1 rounded-[.625rem] border-solid border-piramid-black placeholder:text-stone-300 placeholder:text-xs focus:border-piramid-orange focus-visible:ring-transparent'
                          placeholder='Ingresa tu contraseña'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <div className='text-right'>
                <Link
                  className='text-xs hover:underline text-semibold mt-[.5625rem] mb-[1.0625rem] text-piramid-black inline-block'
                  href={forgotPasswordHref || '/forgot-password'}
                >
                  Olvidé mi contraseña
                </Link>
              </div>

              <Button
                loading={isLoading}
                type='submit'
                className='bg-piramid-black hover:bg-piramid-black h-11.1 rounded-[.625rem] text-[1rem]'
              >
                Iniciar sesión
              </Button>
            </div>
          )}

          {backToIdpStrategy && (
            <Button
              variant={'link'}
              className='mt-6 w-full text-sm text-[#B7B7C0] hover:no-underline pointer-events-none'
            >
              <span
                className='ml-2 font-semibold text-[.9375rem] pointer-events-auto cursor-pointer text-stone-300'
                onClick={(e) => {
                  e.stopPropagation();
                  backToIdpStrategy();
                }}
              >
                Volver al login
              </span>
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};
