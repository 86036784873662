"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var utils_exports = {};
__export(utils_exports, {
  aggregatedToFlattenDamage: () => aggregatedToFlattenDamage,
  buildThemeToken: () => buildThemeToken,
  buildUserFullName: () => buildUserFullName,
  calculateVerificationNumber: () => calculateVerificationNumber,
  capitalizeFirstLetter: () => capitalizeFirstLetter,
  composeAddress: () => composeAddress,
  currencyFormat: () => currencyFormat,
  dayHourToDate: () => dayHourToDate,
  delay: () => delay,
  extractTimeSegments: () => extractTimeSegments,
  getAgeByDateBirth: () => getAgeByDateBirth,
  getCountryLabel: () => getCountryLabel,
  getCountryStates: () => getCountryStates,
  getCurrencyCodeFromCountry: () => getCurrencyCodeFromCountry,
  getHumanFileSize: () => getHumanFileSize,
  getInitialsFromUser: () => getInitialsFromUser,
  getLastItem: () => getLastItem,
  getStateLabel: () => getStateLabel,
  groupByTyped: () => groupByTyped,
  hasDamagesCombination: () => hasDamagesCombination,
  localeDateFormat: () => localeDateFormat,
  lowercaseFirstLetter: () => lowercaseFirstLetter,
  nativeEnumToArray: () => nativeEnumToArray,
  normalizeFilename: () => normalizeFilename,
  sortListAlphabetically: () => sortListAlphabetically,
  stringToRegex: () => stringToRegex,
  uniqueArrayItems: () => uniqueArrayItems,
  utc2utc3: () => utc2utc3
});
module.exports = __toCommonJS(utils_exports);

// src/extract-time-segments.ts
var extractTimeSegments = (time) => {
  const [hour, minute] = time.split(":");
  return {
    hour: Number(hour),
    minute: Number(minute)
  };
};

// src/get-age-by-date-birth.ts
var import_date_fns = require("date-fns");
var getAgeByDateBirth = (dateOfBirth) => (0, import_date_fns.differenceInYears)(/* @__PURE__ */ new Date(), new Date(dateOfBirth));

// src/get-country-label.ts
var import_piramid_constants = require("piramid-constants");
var getCountryLabel = (country) => import_piramid_constants.countryAndStates.find((c) => c.code === country)?.name;

// src/get-human-file-size.ts
var getHumanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1e3 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
};

// src/get-initials-from-user.ts
var getInitialsFromUser = (user) => `${user.first_name.charAt(0).toUpperCase()}${user.last_name?.charAt(0)?.toUpperCase()}`;

// src/get-last-item.ts
var getLastItem = (array = []) => {
  if (!array) {
    return;
  }
  if (!array.length) {
    return;
  }
  return array[array.length - 1];
};

// src/get-state-label.ts
var import_piramid_constants2 = require("piramid-constants");
var getStateLabel = (country, state) => import_piramid_constants2.countryAndStates.find((c) => c.code === country).states.find((s) => s.code === state)?.name;

// src/delay.ts
var delay = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms));

// src/build-theme-token.ts
function buildThemeToken(theme) {
  return `theme-${theme.color_theme}-${theme?.mode}`;
}

// src/has-damages-combination.ts
var import_piramid_constants3 = require("piramid-constants");
var aggregatedToFlattenDamage = (damages) => {
  const flattenTypes = [];
  for (const damage of damages) {
    if (damage.type !== "person") {
      flattenTypes.push(damage.type);
    } else {
      flattenTypes.push(damage.person.type);
    }
  }
  return flattenTypes;
};
var hasDamagesCombination = (candidateCombination, selectedCombination, strict = true) => {
  const predicate = (candidateSegment, selectedSegment) => candidateSegment.type === import_piramid_constants3.flattenDamageTypeToParentType[selectedSegment] && (candidateSegment.subtypes ? candidateSegment.subtypes.includes(selectedSegment) : true);
  if (strict)
    return candidateCombination.length === selectedCombination.length && candidateCombination.every(
      (candidateSegment) => selectedCombination.some(
        (selectedSegment) => predicate(candidateSegment, selectedSegment)
      )
    );
  return selectedCombination.every(
    (selectedSegment) => candidateCombination.some(
      (candidateSegment) => predicate(candidateSegment, selectedSegment)
    )
  );
};

// src/compose-address.ts
var composeAddress = (input) => `${input.address_street} ${input.address_number}${input.address_apartment ? `, ${input.address_apartment}` : ""}`;

// src/get-currency-code-from-country.ts
var import_piramid_constants4 = require("piramid-constants");
var getCurrencyCodeFromCountry = (countryCode) => import_piramid_constants4.countryCurrencyList.find((ele) => ele.country === countryCode)?.currency_code;

// src/build-user-full-name.ts
var buildUserFullName = (user) => `${user.first_name} ${user.last_name}`;

// src/group-by-typed.ts
var groupByTyped = (list, groupByProperty, fallbackKey) => {
  const result = {};
  for (const item of list) {
    const groupKey = item[groupByProperty];
    if (!groupKey && fallbackKey) {
      const fallbackItems = result[fallbackKey];
      if (fallbackItems) {
        result[fallbackKey] = [
          //@ts-ignore
          ...fallbackItems,
          item
        ];
      }
      if (!fallbackItems) {
        result[fallbackKey] = [item];
      }
      continue;
    }
    const group = result[groupKey];
    if (group) {
      result[groupKey].push(item);
    } else {
      result[groupKey] = [item];
    }
  }
  return result;
};

// src/sort-list-alphabetically.ts
var sortListAlphabetically = (list, key) => Array.from(list).sort((a, b) => {
  return a[key].localeCompare(b[key]);
});

// src/native-enum-to-array.ts
var nativeEnumToArray = (obj) => Object.keys(obj);

// src/get-country-state.ts
var import_piramid_constants5 = require("piramid-constants");
var getCountryStates = (country = "") => {
  const states = import_piramid_constants5.countryAndStates.find((option) => option.code === country)?.states || [];
  return (states || []).map((state) => ({
    label: state.name,
    value: state.code
  }));
};

// src/currency-format.ts
var DEFAULT_COUNTRY_CODE = "ARG";
var currencyFormat = (value, country) => new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: getCurrencyCodeFromCountry(country || DEFAULT_COUNTRY_CODE)
}).format(+value);

// src/string-to-regex.ts
var stringToRegex = (input) => {
  const parts = /\/(.*)\/(.*)/.exec(input);
  console.log("parts", parts);
  if (!parts?.length) {
    return new RegExp("");
  }
  return new RegExp(parts[1], parts[2]);
};

// src/capitalize-first-letter.ts
var capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
var lowercaseFirstLetter = (value) => {
  return value.charAt(0).toLowerCase() + value.slice(1);
};

// src/is-valid-day-month-year-date-string.ts
var isValidDayMonthYearDateString = (day) => new RegExp(
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
).test(day);

// src/day-hour-to-date.ts
var import_date_fns_tz = require("date-fns-tz");
var dayHourToDate = (day, hour) => {
  if (!isValidDayMonthYearDateString(day)) {
    return /* @__PURE__ */ new Date();
  }
  const [dayNumber, monthNumber, year] = day.split("/").map(Number);
  const [hourNumber, minuteNumber] = hour.split(":").map(Number);
  const date = /* @__PURE__ */ new Date();
  date.setFullYear(year, monthNumber - 1, dayNumber);
  date.setHours(hourNumber, minuteNumber);
  return (0, import_date_fns_tz.zonedTimeToUtc)(date, "America/Argentina/Buenos_Aires");
};

// src/utc-to-utc-3.ts
var utc2utc3 = (val) => {
  const date = new Date(val);
  const timestamp = date.getTime();
  const offset = 180 * 60 * 1e3;
  date.setTime(timestamp - offset);
  return date;
};

// src/unique-array-items.ts
function uniqueArrayItems(value, index, array) {
  return array.indexOf(value) === index;
}

// src/cuit-verification.ts
function calculateVerificationNumber(cuit) {
  const factor = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cuit.charAt(i), 10) * factor[i];
  }
  const remainder = sum % 11;
  return remainder === 0 ? 0 : remainder === 1 ? 9 : 11 - remainder;
}

// src/locale-date-format.ts
var import_date_fns2 = require("date-fns");
var localeDateFormat = (val) => {
  return (0, import_date_fns2.format)(new Date(val), "dd/MM/yyyy HH:mm");
};

// src/normalize-filename.ts
var import_uuid = require("uuid");
var removeWhiteSpaces = (val) => val.replace(/ /g, "");
var removeNonAscii = (val) => val.replace(/[^\x00-\x7F]/g, "");
var normalizeFilename = (filename) => `${(0, import_uuid.v4)()}-${removeNonAscii(removeWhiteSpaces(filename)).toLowerCase()}`;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  aggregatedToFlattenDamage,
  buildThemeToken,
  buildUserFullName,
  calculateVerificationNumber,
  capitalizeFirstLetter,
  composeAddress,
  currencyFormat,
  dayHourToDate,
  delay,
  extractTimeSegments,
  getAgeByDateBirth,
  getCountryLabel,
  getCountryStates,
  getCurrencyCodeFromCountry,
  getHumanFileSize,
  getInitialsFromUser,
  getLastItem,
  getStateLabel,
  groupByTyped,
  hasDamagesCombination,
  localeDateFormat,
  lowercaseFirstLetter,
  nativeEnumToArray,
  normalizeFilename,
  sortListAlphabetically,
  stringToRegex,
  uniqueArrayItems,
  utc2utc3
});
